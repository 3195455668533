import React from 'react'
import './description.css'

interface DescriptionProps {
    descriptionText: string;
    bgImage: string;
}

export const Description = (
    {
        descriptionText,
        bgImage,
    }: DescriptionProps,
) => (
    <section className='service-description'>
        <div
            className='service-description__image'
            style={{
                backgroundImage: `url("https://n-design.studio${bgImage}")`
            }}
        ></div>
        <h2
            className='service-description__text'
            dangerouslySetInnerHTML={{ __html: descriptionText }}
        />
    </section>
)