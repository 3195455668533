import React, { useCallback, useMemo, useState } from 'react'
import { AllServices } from '../../../types'
import { StarIcon } from '../../../icons';
import './Service.css'

interface ServiceProps {
    services: AllServices;
    header: string;
    button: string;
    language: string;
    isHomePage?: boolean;
}

const SERVICES_KEY = 'service1'

export const Service = (
    {
        services,
        header,
        button,
        language,
        isHomePage = false,
    }: ServiceProps,
) => {
    const [activeKey, setActiveKey] = useState(SERVICES_KEY)

    const headerUrl = useMemo(() => (
        (language === 'ua') ? `/services` : `/${language}/services`
    ), [language])

    const handleClick = useCallback((key: string) => () => {
        setActiveKey(key)
    }, [setActiveKey])

    return (
        <section className='service services-circle container'>
            <h2 className='service__header'>
                <a href={headerUrl}>{header}</a>
            </h2>

            <div className='service__circle'>
                <div className='newSerivces'>
                    <div className='newSerivces__list'>
                        {Object.entries(services).map(([key, service]) => (
                            <div className='newSerivces__list-item'>
                                <div
                                    key={key}
                                    className={`newSerivces__list-item__title ${activeKey === key ? 'active' : ''}`}
                                    onClick={handleClick(key)}
                                >
                                    <div className='title__container'>
                                        <div className='icon'><StarIcon/></div> <span className='title'>{service.title}</span>
                                    </div>
                                </div>

                                <div className={`newService__list-item__text ${activeKey === key ? 'active' : ''}`}>
                                    {service.value}
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>

            {isHomePage && (
                <div className='service__button'>
                    <a href={headerUrl}>
                        <button className='btn btn-primary'>{button}</button>
                    </a>
                </div>
            )}
        </section>
    )
}