import React, { useState, useEffect, useMemo } from 'react'
import { Helmet } from 'react-helmet'
import {
	Text,
	Service,
    AllServices,
} from '../types'
import { MainLayout } from '../components/layouts/Main'
import { SEO as SeoPage} from '../components/ui-components/Seo'
import { Service as ServicePage } from '../components/ui-components/index/Service'
import { Service360 } from '../components/ui-components/service/Service360'
import { Info } from '../components/ui-components/service/Info'
import { Description } from '../components/ui-components/service/Description'
import { WINDOW_MOBILE_WIDTH } from '../common/constants'
import { PriceForm } from '../components/ui-components/forms'
import { getTextByKey } from '../utils'

const ServicesTemplate = (
    {
        pageContext: {
            texts,
            services: allServices,
            meta: metas,
            siteImages,
            language,
        }
    }
) => {
    const meta = metas[0];

    const [ windowWidth, setWindowWidth ] = useState<number>(727)

    const infoText = useMemo(() => (
        texts.find((t: Text) => t.key === 'service-top-info').value
    ), [texts])
    const descriptionText = useMemo(() => (
        texts.find((t: Text) => t.key === 'service-middle-info').value
    ), [texts])

    const services: AllServices = useMemo(() => (
        allServices.reduce((total: AllServices, service: Service) => ({
            ...total,
            [service.key]: {
                title : service.title,
                value : service.value
            },
        }), {})
    ), [allServices])

    const service = useMemo(() => ({
        text: texts.find((t: Text) => t.key === 'about-360-text').value,
        button: texts.find((t: Text) => t.key === 'about-360-more-button').value,
        circle: texts.find((t: Text) => t.key === 'about-360-circle-text').value
    }), [texts])

    const seo = useMemo(() => ({
        title: meta.title,
        description: meta.description,
        keywords: meta.keywords,
        lang: meta.lang
    }), [meta])

    useEffect(() => {
        setWindowWidth(window.outerWidth)
    }, [])

    const bgImage = useMemo(() => {
        const device = windowWidth > WINDOW_MOBILE_WIDTH ? 'desktop' : 'mobile'

        const imageTop = siteImages.find(s => s.key === 'service-page-top')
        const imageMiddle = siteImages.find(s => s.key === 'service-page-middle')

        return {
            top: imageTop[device].publicUrl,
            middle: imageMiddle[device].publicUrl,
        }
    }, [windowWidth, siteImages])

    const priceForm = useMemo(() => ({
        header: getTextByKey(texts, 'contacts-form-header'),
        name: getTextByKey(texts, 'contacts-name-placeholder'),
        phone: getTextByKey(texts, 'contacts-phone-placeholder'),
        email: getTextByKey(texts, 'contacts-email-placeholder'),
        button: getTextByKey(texts, 'contacts-button-more'),
        thanks: getTextByKey(texts, 'contacts-button-thanks')
    }), [getTextByKey, texts])

    return (
        <MainLayout
            urlPrefix='services'
            language={language}
        >
            <Helmet>
                <script>
                    fbq('track', 'ViewContent');
                </script>
            </Helmet>

            <SeoPage {...seo}/>
            <Info
                infoText={infoText}
                bgImage={bgImage.top}
            />
            <ServicePage services={services}/>
            <Description
                descriptionText={descriptionText}
                bgImage={bgImage.middle}
            />
            <Service360 {...service}/>

            <PriceForm form={priceForm}/>
        </MainLayout>
    )
}

export default ServicesTemplate