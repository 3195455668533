import React from 'react'
import './info.css'

interface InfoProps {
    infoText: string;
    bgImage: string;
}

export const Info = (
    {
        infoText,
        bgImage,
    }: InfoProps
) => (
    <section className='service-info'>
        <div
            className='service-info__image'
            style={{
                backgroundImage: `url("https://n-design.studio${bgImage}")`
            }}
        />
        <h2
            className='service-info__text'
            dangerouslySetInnerHTML={{ __html: infoText }}
        />
    </section>
)