import React from 'react'
import { CircleStudioIcon, NewCircleStudio } from '../../../icons';

interface Service360Props {
    text: string;
    circle: string;
}

export const Service360 = (
    {
        text,
        circle
    }: Service360Props
) => (
    <section className='service services-360 container'>
        <div className='service__circle'>
            <div className='service__circle-image'>
                <CircleStudioIcon/>
            </div>
            <div className='service__circle-logo'>
                <NewCircleStudio className='service__circle-logo__image'/>
            </div>
            <div className='service__circle-text'>360°</div>
        </div>
        <div className='service__information'>
            <div className='service__information-text'>
                <h2 className='service__header'>{circle}</h2>
                <div dangerouslySetInnerHTML={{ __html: text }} />
            </div>
        </div>
    </section>
)
